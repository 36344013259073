$base_color: #fff;
$base_font_color: #222C44;
$base_font_color_hover: #fff;
$header_font_color: #0E2E5A;
$special_color: #26a39a;
$svg_color: #B7C1CC;
$border-color: #dfe4e9;

$active_select_bg_color: #26a39a;
$special_font:'Muli', sans-serif;
.mycalendar2_datepicker {
    font-family: $special_font;;
    width:100%;
    margin-bottom: 27px;
    .base_bg_color { background-color:$base_color; }
    .base_font_color { color:$base_font_color; fill:$base_font_color; }
    .base_font_color_hover {
        color:$base_font_color_hover;
        fill:$base_font_color_hover;
        a {
            color:$base_font_color_hover;
        }
    }
    .header_font_color { color: $header_font_color; }
    .special_bg_color p { background-color:$special_color; }
    .special_font_color { color:$special_color; }
    .special_hover_font_color { color:$base_color; }

    header.months_change {
        width:100%;
        border-bottom:1px solid $border-color;
        border-top:1px solid $border-color;
        padding-bottom: 18px;
        padding: 18px 21.6px;
        @media only screen and (max-width: 1200px) {
            padding: 20px 15px;
        }
        .left {
            display:inline-block;
            vertical-align: top;
            text-align: center;
            border: 1px solid #e2e7f1;;
            cursor: pointer;
            width: 26.1px;
            height: 26.1px;
            line-height: 23.4px;
            &:hover svg {
                fill:$base_font_color;
            }

            svg {
                height:20px;
                fill:$svg_color;
            }
        }

        .title {
            width:calc(100% - 53px);
            display:inline-block;
            vertical-align: middle;
            padding-top: 5px;
            :hover {
                cursor: pointer;
            }
            > div{
                display: inline-block;
                vertical-align: middle;
            }
            .month {
                font-weight: 300;
                font-size: 13.05px;
                line-height: 18px;
                font-weight: bold;
                color: #0c2a5f;
            }

            .year {
                padding-left: 5px;
                font-weight: 300;
                font-size:13.05px;
                line-height:18px;
                font-weight: bold;
                color: #0c2a5f;
            }
        }

        .right {
            display:inline-block;
            vertical-align: top;
            text-align: center;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url('./../img/cal_arr_right.png');
            cursor: pointer;
            width: 26.1px;
            height: 26.1px;
            line-height: 23.4px;
            border: 1px solid #e2e7f1;;
            &:hover svg {
                fill:$base_font_color;
            }

            svg {
                height:20px;
                fill:$svg_color;
            }
        }
    }

    .calendar_content {
        position:relative;
        padding:13.5px;



        .days_table {
            width:100%;
            display:table;

            font-size:14px;

            .tr {
                display:table-row;
                .td {
                    display:table-cell;
                    text-align: center;
                    width:14.2%;
                  //  height:35px;

                    p {
                        border-radius: 50px;
                        width: 20.7px;
                        height: 20.7px;
                        line-height: 21px;
                        display: block;
                        font-size: 9.9px;
                        font-weight: 600;
                        margin: 0 auto;
                        margin-bottom: 9px;
                     //   padding-top:8px;
                    }
                }
            }

            .thead {
                display:table-header-group;

                .td {
                    font-weight: bold;
                    p{
                        font-size: 9px;
                        font-weight: 700;;
                        font-family: $special_font;;
                        color: #0c2a5f;;
                    }
                }
            }

            .tbody {
                display: table-row-group;
            }

            .td.mprev, .td.mnext {
                p {
                    opacity:0.2;
                }
            }

            .td.active, .td.active_selected {


                //position:relative;
                cursor: pointer;

                &.active:hover {

                    &.ajax_box_day {
                        -webkit-border-radius: 20px;
                        -webkit-border-bottom-right-radius: 0px;
                        -webkit-border-bottom-left-radius: 0px;
                        -moz-border-radius: 20px;
                        -moz-border-radius-bottomright: 0px;
                        -moz-border-radius-bottomleft: 0px;
                        border-radius: 20px;
                        border-bottom-right-radius: 0px;
                        border-bottom-left-radius: 0px;
                    }

                    background-color:$active_select_bg_color!important;

                    z-index: 3;

                    .popup_html {
                        display:block;
                    }
                }

                p {
                    opacity:1;
                }
            }

            .td.active_selected {
                background-color:$active_select_bg_color;
                color:$base_color;
            }

        }
    }

    .popup_html {
        display:none;

        position:absolute;
        background-color:$active_select_bg_color!important;
        //top:100%;
        //right:0;
        left:0;

        text-align: left;

        width:100%;

        //-webkit-border-radius: 5px;
        //-webkit-border-top-right-radius: 0;
        //-moz-border-radius: 5px;
        //-moz-border-radius-topright: 0;
        //border-radius: 5px;
        //border-top-right-radius: 0;

        z-index:9999;
        ul {
            li {
                padding:5px 10px;
                border-bottom:1px solid $base_color;

                &:last-of-type {
                    border-bottom:0;
                }
            }
        }

    }
}


.calendar_btns{
    width: 100%;;
    background: #ffffff;
    padding: 25px 0;
    font-family: $special_font;
    font-size:14px;
    @media only screen and (max-width: 1500px) {
        font-size: 10px;
    }
    > div{
        ul{
            text-align: center;
            li{
                display: inline-block;
                vertical-align: top;
                margin-right: 17px;
                @media only screen and (max-width: 1500px) {
                    margin-right: 13px;
                }
                @media only screen and (max-width: 1200px) {
                    margin-right: 8px;
                }
                p{
                    color: #0c2a5f;
                    font-weight: 600;;
                }
                a{
                    color: #77849c;;
                    font-weight: 600;;
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
}
